
import { computed, defineComponent, onMounted, reactive, ref } from "vue"
import Datepicker from 'vue3-datepicker'
import { formatDate, formatMoney } from '@/utils/Helpers'
import { AxiosResponse } from "axios"
import ApiService from "@/services/api.service"
import { useStore } from "vuex"

export interface ReportQuotationWarehouse {
  date: string
  Ref: string
  client_name: string
  statut: string
  GrandTotal: number
}

export interface ResponseReportQuotationWarehouse {
  totalRows: number
  quotations: ReportQuotationWarehouse[]
}

export default defineComponent({
  name: 'Quotation',
  components: {
    Datepicker
  },
  setup() {
    const store = useStore()
    const warehouseId = computed<string>({
      get() {
        return store.state.warehouseReport.warehouseId
      },
      set(payload) {
        store.commit('warehouseReport/setWarehouseId', payload)
      }
    })
    const filter = reactive({
      limit: 100,
      SortField: 'date',
      SortType: 'asc',
      search: '',
      warehouse_id: warehouseId
    })
    const picked = ref(new Date())
    const rows = ref<ReportQuotationWarehouse[]>([])
    const getQuotationReport = async () => {
      try {
        const { data }: AxiosResponse<ResponseReportQuotationWarehouse> = await ApiService.get('/report/Quotations_Warehouse', {
          params: filter
        })
        rows.value = data.quotations
      } catch (error) {
        console.log(error);
      }
    }
    onMounted(() => {
      getQuotationReport()
    })
    
    return {
      getQuotationReport,
      picked,
      filter,
      rows,
      formatDate,
      formatMoney
    }
  }
})
